import React, { useState, useRef } from "react";

function ActionButton({ onPress, onRelease, socket }) {
    const [isPressed, setIsPressed] = useState(false);
    const touches = useRef({});

    const handlePress = (event) => {
        if (event.changedTouches) {
            Array.from(event.changedTouches).forEach(touch => {
                touches.current[touch.identifier] = true;
            });
        } else {
            setIsPressed(true);
        }
        onPress({ isPressed: true });
    };

    const handleRelease = (event) => {
        if (event.changedTouches) {
            Array.from(event.changedTouches).forEach(touch => {
                delete touches.current[touch.identifier];
            });
            if (Object.keys(touches.current).length === 0) {
                setIsPressed(false);
            }
        } else {
            setIsPressed(false);
        }
        onRelease({ isPressed: false });
    };

    const handleMouseEvents = (event) => {
        if (!isPressed) {
            // Si le bouton n'est pas pressé, on traite les événements de la souris
            if (event.type === "mouseenter") {
                // Faire quelque chose si la souris entre dans le bouton
            } else if (event.type === "mouseleave") {
                // Faire quelque chose si la souris quitte le bouton
            }
        }
    };

    return (
        <button
            style={{ borderRadius: '20px', width: '150px', height: '100px', position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', backgroundColor: isPressed ? 'green' : 'red' }}
            onTouchStart={handlePress}
            onTouchEnd={handleRelease}
            onMouseDown={handlePress}
            onMouseUp={handleRelease}
            onMouseMove={handleMouseEvents}
            onMouseEnter={handleMouseEvents}
            onMouseLeave={handleMouseEvents}
        />
    );
}

export default ActionButton;