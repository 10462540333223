import React, { useState, useRef, useEffect } from 'react';

function Left_Joystick({ left_onMove, userId }) {
    const [left_position, setPosition] = useState({ x: 0, y: 0 });
    const [isDragging, setIsDragging] = useState(false);
    const touchIndex = useRef(null);
    const clientCoords = useRef({ x: 0, y: 0 });

    const handleStart = (event) => {
        if (event.touches) {
            touchIndex.current = event.touches.length > 1 ? 1 : 0;
            clientCoords.current = {
                x: event.touches[touchIndex.current].clientX,
                y: event.touches[touchIndex.current].clientY,
            };
        } else {
            clientCoords.current = { x: event.clientX, y: event.clientY };
        }

        setIsDragging(true);
        handleMove(event);
    };

    const handleMove = (event) => {
        if (!isDragging) return;

        let { x: clientX, y: clientY } = clientCoords.current;

        if (event.touches && event.touches[touchIndex.current]) {
            clientX = event.touches[touchIndex.current].clientX;
            clientY = event.touches[touchIndex.current].clientY;
            clientCoords.current = { x: clientX, y: clientY };
        }

        const rect = event.currentTarget.getBoundingClientRect();
        let offsetX = clientX - rect.left - rect.width / 2;
        let offsetY = clientY - rect.top - rect.height / 2;

        const distance = Math.sqrt(offsetX ** 2 + offsetY ** 2);
        const maxDistance = rect.width / 2;
        if (distance > maxDistance) {
            const angle = Math.atan2(offsetY, offsetX);
            offsetX = Math.cos(angle) * maxDistance;
            offsetY = Math.sin(angle) * maxDistance;
        }

        const x = offsetX / (rect.width / 2);
        const y = offsetY / (rect.height / 2);

        setPosition({ x, y: -y, userId });
        left_onMove({ x, y: -y, userId });
    };

    const handleStop = () => {
        setIsDragging(false);
        setPosition({ x: 0, y: 0, userId });
        touchIndex.current = null;
    };

    // useEffect(() => {
    //     const intervalId = setInterval(() => {
    //         if (isDragging && (left_position.x !== 0 || left_position.y !== 0)) {
    //             // Envoie les coordonnées si le joystick est déplacé et que le drag est en cours
    //             left_onMove(left_position);
    //         }
    //     }, 1);

    //     return () => clearInterval(intervalId); // Nettoie l'intervalle lors du démontage du composant
    // }, [left_position, isDragging]); // Se redéclenche si la position ou l'état de glissement changent

    useEffect(() => {
        if (isDragging) {
            left_onMove(left_position);
        } else {
            left_onMove({ x: 0, y: 0, userId });
        }
        left_onMove(left_position);
    }, [left_position]);

    return (
        <div
            className="left_joystick"
            onTouchStart={handleStart}
            onTouchMove={handleMove}
            onTouchEnd={handleStop}
            onMouseDown={handleStart}
            onMouseMove={handleMove}
            onMouseUp={handleStop}
            onMouseLeave={handleStop}
            style={{
                width: '150px',
                height: '150px',
                backgroundColor: 'black',
                borderRadius: '50%',
                position: 'absolute',
                left: '25%', top: '50%',
                transform: 'translate(-50%, -50%)',
                boxShadow: '0 0 5px 2px rgba(65, 65, 150, 0.5)'
            }}
        >
            <div
                className='left_joystick_inner'
                style={{
                    width: '80px',
                    height: '80px',
                    backgroundColor: 'white',
                    border: '2px solid black',
                    borderRadius: '50%',
                    position: 'absolute',
                    left: `${left_position.x * 50 + 50}%`,
                    top: `${-left_position.y * 50 + 50}%`,
                    transform: 'translate(-50%, -50%)',
                    boxShadow: '0 0 5px 2px rgba(65, 65, 150, 0.5)'
                }}
            />
        </div>
    );
}

export default Left_Joystick;