import React, { useEffect, useState } from "react";
import io from 'socket.io-client';
import Left_Joystick from "./components/left_joystick";
import ActionButton from "./components/action_button";
import Right_Joystick from "./components/right_joystick";
import "./App.css";

const socket = io('http://192.168.1.59:4000');

const randomNames = [
  "Jean Bon", "Alain Posteur", "Alex Terrier", "Anna Lyste", "Marc Assin",
  "Claire Voie", "Sarah Croche", "Paul Hémique", "Guy Tare", "Emma Gogue",
  "Ella Boration", "Nico Las", "Léo Part", "Sam Suffit", "Harry Covert",
  "Tom Ate", "Yves Théière", "Louis Sillon", "Phil Anthropie", "Max Immum"
];

function getRandomName() {
  return randomNames[Math.floor(Math.random() * randomNames.length)];
}

function App() {
  const [userId, setUserId] = useState(null);
  const [gameId, setGameId] = useState('');
  const [username, setUsername] = useState('');
  const [isGameConnected, setIsGameConnected] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    socket.on('userId', (id) => {
      setUserId(id);
      setIsGameConnected(true);
    });

    socket.on('error', (message) => {
      setErrorMessage(message);
    });

    return () => {
      socket.off('userId');
      socket.off('error');
    };
  }, []);

  const joinGame = () => {
    const finalUsername = username || getRandomName();
    if (gameId) {
      socket.emit('joinGame', { gameId, username: finalUsername });
    }
  };

  const handleRightJoystickMove = (left_position) => {
    socket.emit('right_joystickMove', left_position);
  };

  const handleLeftJoystickMove = (right_position) => {
    socket.emit('left_joystickMove', right_position);
  };

  const handlePress = ({ isPressed }) => {
    console.log("Button pressed by client:", userId, "on game:", gameId);
    socket.emit('actionButton', { gameId, userId, isPressed: true });
  };

  const handleRelease = ({ isPressed }) => {
    console.log("Button released by client:", userId);
    socket.emit('actionButton', { gameId, userId, isPressed: false });
  };

  return (
    <div className="App">
      {!isGameConnected ? (
        <div className="login-container">
          <input 
            type="text" 
            placeholder="Enter Your Name" 
            value={username} 
            onChange={(e) => setUsername(e.target.value)} 
          />
          <input 
            type="text" 
            placeholder="Enter Game ID" 
            value={gameId} 
            onChange={(e) => setGameId(e.target.value)} 
          />
          <button onClick={joinGame}>Join Game</button>
          {errorMessage && <p className="error-message">{errorMessage}</p>}
        </div>
      ) : (
        <div className="game-container">
          <Left_Joystick socket={socket} left_onMove={handleLeftJoystickMove} userId={userId} />
          <ActionButton onPress={handlePress} onRelease={handleRelease} socket={socket} />
          <Right_Joystick socket={socket} right_onMove={handleRightJoystickMove} userId={userId} />
        </div>
      )}
    </div>
  );
}

export default App;
